import React from 'react';

export default function Numbers() {
    return (
        <div className="bg-gray-50 mt-12 pt-8 sm:pt-16">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="max-w-4xl mx-auto text-center">
                    <h2 className="text-3xl font-extrabold text-gray-900 sm:text-4xl">
                        Robust solution for the entire team
                    </h2>
                    {/*<p className="mt-3 text-xl text-gray-500 sm:mt-4">*/}
                    {/*    Receive payments, manage guests, build your own CRM for your business.*/}
                    {/*</p>*/}
                </div>
            </div>
            <div className="mt-10 pb-12 bg-white sm:pb-16">
                <div className="relative">
                    <div className="absolute inset-0 h-1/2 bg-gray-50" />
                    <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                        <div className="max-w-4xl mx-auto">
                            <dl className="rounded-lg bg-white shadow-lg sm:grid sm:grid-cols-3">
                                <div className="flex flex-col border-b border-gray-100 p-6 text-center sm:border-0 sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">guests</dt>
                                    <dd className="order-1 text-5xl font-extrabold text-indigo-600">&#8734;</dd>
                                </div>
                                <div className="flex flex-col border-t border-b border-gray-100 p-6 text-center sm:border-0 sm:border-l sm:border-r">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">managers</dt>
                                    <dd className="order-1 text-5xl font-extrabold text-indigo-600">&#8734;</dd>
                                </div>
                                <div className="flex flex-col border-t border-gray-100 p-6 text-center sm:border-0 sm:border-l">
                                    <dt className="order-2 mt-2 text-lg leading-6 font-medium text-gray-500">per month</dt>
                                    <dd className="order-1 text-5xl font-extrabold text-indigo-600">$25</dd>
                                </div>
                            </dl>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
