import React from 'react';
import { Disclosure } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/outline'

const faqs = [
    {
        id: 1,
        question: "Is there any access control?",
        answer:
            "Access control to our system is managed through our user roles. Each employee is assigned a user role when they are added and are governed by permissions.",
    },
    {
        id: 2,
        question: "What happens with the entire sober house if I haven't paid for a subscription?",
        answer:
            "You have about two months to up your balance above $0. If a purchase hasn't been done - you just lose your management opportunity. After debts are paid - you get your access back.",
    },
    {
        id: 3,
        question: "Can I restore deleted data?",
        answer:
            "" +
            "All data stored is safe and recoverable, protecting customers against accident loss or mistakes.",
    },
    {
        id: 4,
        question: "What can I do if there isn’t some feature that I need?",
        answer:
            "Let us know! Much possible it is currently under development, or we’ll set it to the development plan.",
    },
//    {
//        id: 5,
//        question: "Why can't you hear a pterodactyl go to the bathroom?",
//        answer:
//            "Because the pee is silent. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
//    },
//    {
//        id: 6,
//        question: "Why did the invisible man turn down the job offer?",
//        answer:
//            "He couldn't see himself doing it. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
//    },
    // More questions...
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function FAQ() {
    return (
        <div id="faq" className="bg-gray-50">
            <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
                <div className="max-w-3xl mx-auto divide-y-2 divide-gray-200">
                    <h2 className="text-center text-3xl font-extrabold text-gray-900 sm:text-4xl">Frequently asked questions</h2>
                    <dl className="mt-6 space-y-6 divide-y divide-gray-200">
                        {faqs.map((faq) => (
                            <Disclosure as="div" key={faq.question} className="">
                                {({ open }) => (
                                    <>
                                    <Disclosure.Button className="text-lg pt-6 w-full">
                                        <div className="text-left flex justify-between items-start text-gray-400">
                                            <span className="font-medium text-gray-900">{faq.question}</span>
                                            <span className="ml-6 h-7 flex items-center">
                                              <ChevronDownIcon
                                                  className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                  aria-hidden="true"
                                              />
                                            </span>
                                        </div>
                                    </Disclosure.Button>
                                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                        <p className="text-base text-gray-500">{faq.answer}</p>
                                    </Disclosure.Panel>
                                    </>
                                )}
                            </Disclosure>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
