import React from 'react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import Navbar from './Navbar';
import MainScreen from './MainScreen';
import Features from './Features';
import Advantages from './Advantages';
import CTA from './CTA';
import Pricing from './Pricing';
import FAQ from './FAQ';
import Numbers from './Numbers';
import Testimonials from './Testimonials';
import Footer from './Footer';
import Footer2 from './Footer2';



export default function MainPage() { // index
    return (
        <div className="min-h-screen">
            <div className="relative overflow-hidden">
            <Navbar />

                <main>
                    <MainScreen />

                    <Numbers />
                    {/*<LogoCloud />*/}
                    <Features />
                    <CTA />
                    <Advantages />

                    <Pricing />
                    <Testimonials />

                    <FAQ />

                    {/*<Footer />*/}
                    <Footer2 />

                    {/* More main page content here... */}
                </main>
            </div>
        </div>
    )
}
