import React from 'react';
import { CheckIcon } from '@heroicons/react/outline'

const features = [
    {
        name: 'House management',
        description: 'Manage multiple sober houses in one place. Set custom room capacity, pricing and permissions',
    },
    {
        name: 'Guest management',
        description: 'Collect member data in our CRM. Easily access guest information, details, contacts and documents' },
    {
        name: 'Room management',
        description: 'Use the bed management system to replace your whiteboards and spreadsheets',
    },
    {
        name: 'Payment management',
        description: 'Save time by collecting payments digitally through our integrated payment gateway' },
    {
        name: 'Referral management',
        description: 'View, track and offer custom referral incentives all in one place' },
    {
        name: 'Invoices',
        description: 'Automated invoicing in customizable terms' },
    {
        name: 'Newsletters',
        description: 'Share information with your members electronically' },
    {
        name: 'Guest accounts',
        description: 'Each member can access and view account balances through the guest account' },
]

export default function Advantages() {
    return (
        <div id="features" className="bg-white">
            <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:grid lg:grid-cols-3 lg:gap-x-8">
                <div>
                    <h2 className="text-base font-semibold text-indigo-600 uppercase tracking-wide">Everything you need</h2>
                    <p className="mt-2 text-3xl font-extrabold text-gray-900">All-in-one platform</p>
                    <p className="mt-4 text-lg text-gray-500">
                        Specifically designed powerful CRM/ERP system providing all the tools you need.
                    </p>
                </div>
                <div className="mt-12 lg:mt-0 lg:col-span-2">
                    <dl className="space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:grid-rows-4 sm:grid-flow-col sm:gap-x-6 sm:gap-y-10 lg:gap-x-8">
                        {features.map((feature) => (
                            <div key={feature.name} className="relative">
                                <dt>
                                    <CheckIcon className="absolute h-6 w-6 text-green-500" aria-hidden="true" />
                                    <p className="ml-9 text-lg leading-6 font-medium text-gray-900">{feature.name}</p>
                                </dt>
                                <dd className="mt-2 ml-9 text-base text-gray-500">{feature.description}</dd>
                            </div>
                        ))}
                    </dl>
                </div>
            </div>
        </div>
    )
}
